@font-face {
font-family: 'notoSansKR';
src: url(/_next/static/media/e60535a8d1195192-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: 'notoSansKR';
src: url(/_next/static/media/19c9337c12b742a5-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'notoSansKR';
src: url(/_next/static/media/6cee3a75dfc5ee0f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}@font-face {font-family: 'notoSansKR Fallback';src: local("Arial");ascent-override: 114.47%;descent-override: 28.42%;line-gap-override: 0.00%;size-adjust: 101.34%
}.__className_7856ef {font-family: 'notoSansKR', 'notoSansKR Fallback'
}

